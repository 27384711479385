import React, { useEffect } from "react";
import { styled, keyframes } from "../stitches.config";
import { useStores } from "../helpers";
import { observer } from "mobx-react";

const Loader = observer(() => {

  const { UIStore } = useStores();

  useEffect(() => {
    UIStore.isLoader = true;
    return () => UIStore.isLoader = false;
  }, []);

  return (
    <LoaderWrapper>
      <AnimatedLogo src={UIStore.appTheme.logoSimple} />
    </LoaderWrapper>
  );

});

export default Loader;

const LoaderWrapper = styled("div", {
  height: "100vh",
  marginBottom: "200px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
});

const fadeInOut = keyframes({
  "0%, 100%": {
    opacity: 1
  }, "50%": {
    opacity: 0.1
  }
});

const AnimatedLogo = styled("img", {
  height: "100px",
  marginBottom: "25px",
  boxShadow: "9px 9px 16px 1px rgba(0, 0, 0, 0.15)",
  opacity: 1,
  animation: `${fadeInOut} 1.5s ease-in-out infinite`
});
