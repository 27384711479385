import React, { Suspense } from "react"
import FooterPlaceholder from "./FooterPlaceholder";
import { useStores } from "../../../helpers";
import { observer } from "mobx-react-lite";

const FooterFull = React.lazy(() =>/* webpackChunkName: "Footer" */ import('./Footer'));

const Footer: React.FC = observer(() => {

    const { UIStore } = useStores();

    if (UIStore.isLoader)
        return null;

    return (
        <FooterPlaceholder className="footer-placeholder">
            <Suspense fallback={null}>
                <FooterFull />
            </Suspense>
        </FooterPlaceholder>
    );

});

export default Footer;