import React from "react";
import { styled } from "../../../stitches.config";

const FooterPlaceholder = styled("div", {
    minHeight: "940px",
    "& > footer": {
        height: "100%"
    },
    "@bp4": {
        minHeight: "344px",
    }
});

export default FooterPlaceholder;